import { Link } from "react-scroll";
import ReactTypingEffect from "react-typing-effect";

/*--------------------
* Banner Section
----------------------*/
export default function Banner() {
  return (
    <>
      <section
        data-scroll-data="0"
        id="home"
        className="home-section pt-[120px] pb-[80px] lg:pt-[180px] lg:pb-[100px] bg-orange-50 relative overflow-hidden"
      >
        <div className="container relative z-[1]">
          <div className="grid lg:grid-cols-12 grid-cols-1 items-center">
            <div className="lg:col-span-6">
              <div className="text-center lg:text-start mb-[50px] lg:mb-0">
                <h6 className="text-black uppercase text-[14px] md:text-[16px] tracking-[2px] font-[600] mb-[20px] md:mb-[30px]">
                  🏢, 경영전략시뮬레이션
                </h6>
                <h1 className="text-black font-[600] text-[40px] lg:text-[60px] xl:text-[70px] leading-[1] mb-[25px] md:mb-[35px]">
                  MaTS{" "}
                  <span id="type-it">
                    <ReactTypingEffect
                      text={["Game", "Simulation", "Strategy"]}
                      speed="100"
                      typingDelay="500"
                      eraseDelay="200"
                    />
                  </span>
                </h1>
                <p className="text-[16px] md:text-[20px] mb-[8px] md:mb-[16px]">
                  Management Technique Simulation
                </p>
                <div className="pt-[10px] flex gap-[10px] w-full">
                  <a href="/apply" target="_blank" rel="noopener noreferrer">
                    <button
                      className="px-btn px-btn-theme2"
                      data-scroll-nav="4"
                    >
                      퍼실리에이터 양성과정 신청
                    </button>
                  </a>
                  <Link className="px-btn px-btn-theme" to="contactus">
                    <span>이용/제휴 문의</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="lg:col-span-6">
              <div className="home-image text-center">
                <iframe
                  src="https://www.youtube.com/embed/6Bv_8Q6GSUA?si=0x4LjJsP-zHIQ3sN"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                  className="w-full min-h-[300px] rounded-[10px]"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
