import axios from "axios";
import { useState } from "react";

/*--------------------
* Faciliator's Application Section
----------------------*/
export default function Application() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [institution, setInstitution] = useState("");
  const [title, setTitle] = useState("");
  const [contents, setContents] = useState("");
  const [course, setCourse] = useState("3");

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!name) {
      alert("이름을 입력해주세요.");
      return false;
    }

    if (!email) {
      alert("이메일을 입력해주세요.");
      return false;
    }

    if (!phone) {
      alert("연락처를 입력해주세요.");
      return false;
    }

    if (!institution) {
      setInstitution(" ");
    }

    if (!title) {
      alert("신청목적을 입력해주세요.");
      return false;
    }

    if (
      !window.confirm(
        `${course}기수에 지원하시는게 맞는지 다시 한번 확인해주세요. 계속 진행하시겠습니까?`
      )
    ) {
      alert("취소되었습니다. 원하시는 기수를 선택하시어 다시 진행해주세요.");
      return false;
    }

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/info/application`,
        {
          name: name,
          email: email,
          phone: phone,
          institution: institution,
          title: title,
          contents: contents,
          course: course,
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      if (data.errorCode) {
        alert(
          "일시적인 오류가 발생하였습니다. 잠시 후 다시 시도해주세요. 계속 오류 발생 시 관리자에게 문의주세요.(biznplay@naver.com)"
        );
        return false;
      }

      alert(
        "퍼실리에이터 양성과정에 지원해주셔서 감사합니다. 지원이 완료되었습니다. 담당자가 확인 후 연락드리겠습니다."
      );

      setName("");
      setEmail("");
      setPhone("");
      setInstitution("");
      setTitle("");
      setContents("");
      setCourse("2");
    } catch (error) {
      console.log("====================================");
      console.log("error", error);
      console.log("====================================");
      alert(
        "일시적인 오류가 발생하였습니다. 잠시 후 다시 시도해주세요. 계속 오류 발생 시 관리자에게 문의주세요.(biznplay@naver.com)"
      );
      return false;
    }
  };

  return (
    <>
      <section
        data-scroll-data="4"
        id="contactus"
        className="section contactus-section bg-slate-900"
        style={{
          backgroundImage: "url(img/effect/bg-effect-3.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container max-w-[900px]">
          <div className="grid grid-cols-12">
            <div className="col-span-12">
              <div className="p-[25px] md:p-[35px] bg-white flex flex-col">
                <h6 className="text-[32px] font-[600] text-black mb-[5px]">
                  퍼실리에이터 양성과정 3기 신청페이지
                </h6>
                <p className="text-[18px] mb-[30px]">
                  경영전략시뮬레이션 퍼실리에이터 전문가 양성과정 신청을 위해
                  아래 양식을 작성해주세요.
                  <br />
                  <br />
                  ** 신청기간 : 2024.09.09 ~ 2024.10.18
                  <br />
                  ** 과정과 관련한 문의사항이 있으신 경우
                  'biznplay@naver.com'으로 문의해주세요.
                </p>
                <img src="img/poster/mats-3-poster.jpeg" alt="" />
                <hr className="mt-10 mb-10" />
                <form id="contact-form" onSubmit={handleFormSubmit}>
                  <div className="grid grid-cols-12 gap-3">
                    <div className="col-span-12 md:col-span-6">
                      <div className="form-group">
                        <label className="form-label">성함</label>
                        <input
                          name="Name"
                          id="name"
                          placeholder="성함을 기재해주세요. *"
                          className="form-control"
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <div className="form-group">
                        <label className="form-label">이메일 주소</label>
                        <input
                          name="Email"
                          id="email"
                          placeholder="이메일 주소를 기재해주세요. *"
                          className="form-control"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="form-group">
                        <label className="form-label">연락처</label>
                        <input
                          name="Phone"
                          id="phone"
                          placeholder="연락받으실 전화번호를 입력해주세요. *"
                          className="form-control"
                          type="text"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="form-group">
                        <label className="form-label">신청기수</label>
                        <select
                          name="Course"
                          id="course"
                          className="form-control"
                          value={course}
                          onChange={(e) => setCourse(String(e.target.value))}
                        >
                          {/* <option value="1">1기(2월 17일 / 2월 24일)</option> */}
                          <option value="1" disabled>
                            1기(3월 16일 / 3월 23일)
                          </option>
                          <option value="2" disabled>
                            2기(7월 19일 / 7월 20일)
                          </option>
                          <option value="3">3기(10월 19일 / 10월 26일)</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="form-group">
                        <label className="form-label">소속</label>
                        <input
                          name="Institution"
                          id="institution"
                          placeholder="소속이 없으신 경우 빈칸 *"
                          className="form-control"
                          type="text"
                          value={institution}
                          onChange={(e) => setInstitution(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="form-group">
                        <label className="form-label">신청목적</label>
                        <input
                          name="Title"
                          id="title"
                          placeholder="신청목적을 입력해주세요. *"
                          className="form-control"
                          type="text"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="form-group">
                        <label className="form-label">
                          문의사항
                          <span className="text-xs">
                            (본 과정과 관련하여 문의사항이 있으신 경우 아래에
                            적어주세요)
                          </span>
                        </label>
                        <textarea
                          name="message"
                          id="message"
                          rows="4"
                          className="form-control"
                          value={contents}
                          onChange={(e) => setContents(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="send flex justify-center">
                        <button
                          className="px-btn px-btn-theme2"
                          type="submit"
                          value="Send"
                        >
                          {" "}
                          신청하기
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
