import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";

/*--------------------
* Testimonial Section
----------------------*/
export default function Testimonial() {
  const testimonail_list = [
    {
      id: 1,
      image: "img/avatar1.jpeg",
      //   author_name: "Jennifer Lutheran",
      //   position: "CEO at pxdraft",
      description:
        "게임을 통해 경영의 세계에 더 깊이 빠져들게 되었습니다. 다양한 상황에서 어떻게 해야 하는지 고민하는 것이 정말 재미있습니다. 모든 대학생에게 추천하고 싶은 게임입니다 🤔",
    },
    {
      id: 2,
      image: "img/avatar1.jpeg",
      //   author_name: "Jennifer Lutheran",
      //   position: "CEO at Envento",
      description:
        "경영의 어려움과 전략의 중요성을 깨닫게 되었습니다. 게임이지만, 실제와 같은 경험을 할 수 있어서 매우 만족스럽습니다 👍👍",
    },
    {
      id: 3,
      image: "img/avatar1.jpeg",
      //   author_name: "Jennifer Lutheran",
      //   position: "CEO at ThemeForest",
      description:
        "게임 내에서 다양한 경영 전략을 시도해볼 수 있어서 정말 좋았습니다. 실패해도 다시 도전할 수 있어서, 실제 경영 상황에서 유용한 경험을 쌓을 수 있었습니다 🔥🔥🔥",
    },
    {
      id: 4,
      image: "img/avatar1.jpeg",
      //   author_name: "Jennifer Lutheran",
      //   position: "CEO at Ebay",
      description:
        "경영에 대한 이해도가 높아진 것 같아요. 실제로 비즈니스를 운영하는 것과 유사한 경험을 할 수 있어서 좋았습니다.",
    },
    {
      id: 5,
      image: "img/avatar1.jpeg",
      //   author_name: "Jennifer Lutheran",
      //   position: "CEO at pxdraft",
      description:
        "중독성이 정말 대단합니다. 시간 가는 줄 모르고 플레이하게 되네요. 경영 전략을 고민하면서 동시에 즐거움을 느낄 수 있어서 좋습니다.",
    },
  ];

  return (
    <>
      <section className="section testimonial-section">
        <div className="container">
          <div className="grid section-heading">
            <div className="lg:col-span-6 text-center mx-auto">
              <h3>
                <span>생생한 후기</span>
              </h3>
            </div>
          </div>
          <Swiper
            spaceBetween={30}
            slidesPerView={2}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            pagination={{ clickable: true }}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              992: {
                slidesPerView: 2,
              },
            }}
          >
            {testimonail_list.map((val, i) => {
              return (
                <SwiperSlide key={i}>
                  <div className="feature-box-03">
                    <div className="feature-img">
                      <img src={val.image} title={val.image} alt={val.image} />
                    </div>
                    <div className="feature-content">
                      <div className="icons">
                        <i className="fas fa-quote-left"></i>
                      </div>
                      <p>{val.description}</p>
                      <h6>{val.author_name}</h6>
                      <span>{val.position}</span>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </section>
    </>
  );
}
