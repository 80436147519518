/*--------------------
* Service Section
----------------------*/
function Service(props) {
  return (
    <>
      <div className="lg:col-span-6">
        <div className={props.id.main + " feature-box-01"}>
          <div className="icon">
            <i className={props.id.icon}></i>
          </div>
          <div className="feature-content">
            <h5>{props.title}</h5>
            <p>{props.description}</p>
          </div>
        </div>
      </div>
    </>
  );
}

/*--------------------
* Service List Section
----------------------*/
export default function ServiceList() {
  const service_list = [
    {
      id: { main: "bg-1", icon: "fas fa-user-plus" },
      title: "모인다",
      description:
        "CEO부터 CTO, CFO 등 모든 의사결정 담당자의 역할이 모여 팀을 이룹니다.",
    },
    {
      id: { main: "bg-2", icon: "fas fa-chart-pie" },
      title: "분석한다",
      description:
        "각 역할을 소유한 참가자는 AI 또는 진행자가 결정하는 해당 분기의 시장상황을 분석합니다.",
    },
    {
      id: { main: "bg-3", icon: "fas fa-gavel" },
      title: "결정한다",
      description:
        "어떤 것이 우리 회사의 전략적 의사결정인지 판단하고 토론하고 최종결정을 합니다.",
    },
    {
      id: { main: "bg-4", icon: "fas fa-star" },
      title: "성취한다 ",
      description:
        "아주 상세한 리포트 및 경쟁을 통해 경영환경에서의 성취를 이룹니다.",
    },
  ];
  return (
    <>
      <section
        data-scroll-data="1"
        id="services"
        className="section services-section bg-gray"
        style={{
          backgroundImage: "url(img/effect/bg-effect-1.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="grid section-heading">
            <div className="lg:col-span-6 text-center mx-auto">
              <h3>
                <span>MaTS 게임의 특징</span>
              </h3>
            </div>
          </div>
          <div className="grid lg:grid-cols-12 md:grid-cols-2 gap-5 grid-cols-1 items-center">
            {service_list.map((val, i) => {
              return (
                <Service
                  key={i}
                  id={val.id}
                  title={val.title}
                  description={val.description}
                />
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}
