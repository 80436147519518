import axios from "axios";
import { useState } from "react";

/*--------------------
* Contact Section
----------------------*/

export default function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [institution, setInstitution] = useState("");
  const [title, setTitle] = useState("");
  const [contents, setContents] = useState("");

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!name) {
      alert("이름을 입력해주세요.");
      return false;
    }

    if (!email) {
      alert("이메일을 입력해주세요.");
      return false;
    }

    if (!phone) {
      alert("연락처를 입력해주세요.");
      return false;
    }

    if (!institution) {
      alert("소속을 입력해주세요.");
      return false;
    }

    if (!title) {
      alert("제목을 입력해주세요.");
      return false;
    }

    if (!contents) {
      alert("내용을 입력해주세요.");
      return false;
    }

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/info/contact`,
        {
          name: name,
          email: email,
          phone: phone,
          institution: institution,
          title: title,
          contents: contents,
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      if (data.errorCode) {
        alert(
          "일시적인 오류가 발생하였습니다. 잠시 후 다시 시도해주세요. 계속 오류 발생 시 관리자에게 문의주세요.(biznplay@naver.com)"
        );
        return false;
      }

      alert("문의가 접수되었습니다. 빠른 시일 내에 답변드리겠습니다.");
    } catch (error) {
      alert(
        "일시적인 오류가 발생하였습니다. 잠시 후 다시 시도해주세요. 계속 오류 발생 시 관리자에게 문의주세요.(biznplay@naver.com)"
      );
    }

    // const { data, error } = await supabase.from("contacts").insert([
    //   {
    //     name: name,
    //     email: email,
    //     phone: phone,
    //     institution: institution,
    //     title: title,
    //     contents: contents,
    //   },
    // ]);

    // if (error) {
    //   console.log("====================================");
    //   console.log("error", error);
    //   console.log("====================================");
    //   alert("오류가 발생하였습니다.");
    //   return false;
    // }

    // if (data) {
    //   console.log("====================================");
    //   console.log("data", data);
    //   console.log("====================================");
    // }
  };

  return (
    <>
      <section
        data-scroll-data="4"
        id="contactus"
        className="section contactus-section bg-slate-900"
        style={{
          backgroundImage: "url(img/effect/bg-effect-3.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div className="grid grid-cols-12">
            <div className="col-span-12 lg:col-span-6 ">
              <div className="p-[25px] md:p-[35px] bg-white">
                <h6 className="text-[32px] font-[600] text-black mb-[5px]">
                  서비스 이용/제휴 문의
                </h6>
                <p className="text-[18px] mb-[30px]">
                  서비스 이용, 제휴, 기타 문의사항이 있으시면 아래 양식을 통해
                  문의해주세요.
                </p>
                <form
                  id="contact-form"
                  //   method="POST"
                  onSubmit={handleFormSubmit}
                >
                  <div className="grid grid-cols-12 gap-3">
                    <div className="col-span-12 md:col-span-6">
                      <div className="form-group">
                        <label className="form-label">성함(담당자명)</label>
                        <input
                          name="Name"
                          id="name"
                          placeholder="성함을 기재해주세요. *"
                          className="form-control"
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <div className="form-group">
                        <label className="form-label">이메일 주소</label>
                        <input
                          name="Email"
                          id="email"
                          placeholder="이메일 주소를 기재해주세요. *"
                          className="form-control"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="form-group">
                        <label className="form-label">연락처</label>
                        <input
                          name="Phone"
                          id="phone"
                          placeholder="연락받으실 전화번호를 입력해주세요. *"
                          className="form-control"
                          type="text"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="form-group">
                        <label className="form-label">기관(업체, 대학)명</label>
                        <input
                          name="Institution"
                          id="institution"
                          placeholder="기관명을 입력해주세요. *"
                          className="form-control"
                          type="text"
                          value={institution}
                          onChange={(e) => setInstitution(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="form-group">
                        <label className="form-label">문의사항 제목</label>
                        <input
                          name="Title"
                          id="title"
                          placeholder="제목을 입력해주세요."
                          className="form-control"
                          type="text"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="form-group">
                        <label className="form-label">문의사항 내용</label>
                        <textarea
                          name="message"
                          id="message"
                          placeholder="궁금하신 사항을 입력해주세요."
                          rows="4"
                          className="form-control"
                          value={contents}
                          onChange={(e) => setContents(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="send">
                        <button
                          className="px-btn px-btn-theme2"
                          type="submit"
                          value="Send"
                        >
                          {" "}
                          작성하기
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-6 flex">
              <div className="lg:max-w-[410px] w-full lg:ml-auto pt-[50px] lg:pt-0">
                <ul className="contact-infos">
                  <li>
                    <div className="icon bg-1">
                      <i className="fas fa-envelope"></i>
                    </div>
                    <div className="col">
                      <h5>Phone</h5>
                      <p>010 9412 8209</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon bg-2">
                      <i className="fas fa-phone-alt"></i>
                    </div>
                    <div className="col">
                      <h5>Mail</h5>
                      <p>biznplay@naver.com</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
