import { BrowserRouter, Route, Routes } from "react-router-dom";
import Krinky from "./components/Origin/krinky";
import Apply from "./pages/Apply";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Krinky />} />
        <Route path="/apply" element={<Apply />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
