import Header from "../Header/header";
import Banner from "../Banner/banner";
import BrandSlider from "../Brand/brand";
import About from "../About/about";
import ServiceList from "../Service/service";
import SkillList from "../Skills/skill";
import Work from "../Work/work";
import Testimonial from "../Testimonial/testimonial";
import Contact from "../Contact/contact";
import Footer from "../Footer/footer";
import Intrested from "../Intrested/intrested";
import { Button, Modal, Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";

/*--------------------
* Building Krinky
----------------------*/

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Krinky() {
  return (
    <>
      <Header />
      <main className="wrapper">
        <Banner />
        {/* <BrandSlider /> */}
        <About />
        <ServiceList />
        {/* <SkillList/> */}
        <Intrested />
        <Work />
        <Testimonial />
        <Contact />
      </main>
      <Footer />
    </>
  );
}
