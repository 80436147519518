import { Link } from "react-scroll";

/*--------------------
* About Section
----------------------*/
export default function About() {
  return (
    <>
      <section className="section">
        <div className="container">
          <div className="grid lg:grid-cols-12 grid-cols-1 items-center">
            <div className="lg:col-span-6 text-center mb-[50px] lg:mb-0">
              <img
                className="mx-auto rounded-[30px]"
                src="img/main-page-banner.png"
                title="Banner"
                alt="Banner"
              />
            </div>
            <div className="lg:col-span-6 lg:pl-12">
              <h3 className="font-[600] text-[26px] md:text-[40px] leading-[1.2] text-black mb-[25px]">
                경영전략 마스터, MaTS
              </h3>
              <p className="text-[16px] md:text-[18px]">
                MaTS는 시대의 흐름에 따라 복잡해진 경영환경을 웹과 모바일
                애플리케이션을 통해 언제 어디서든 체험할 수 있는 혁신적인
                경영전략 시뮬레이션 게임입니다. 기존의 이론 중심의 교육에서
                벗어나 실전과 같은 의사결정 과정과 경쟁을 통해 사용자들에게
                전략적 마인드를 향상시키고 실질적인 경영 이해를 제공합니다.
                MaTS와 함께 경영 전략의 마스터가 되어보세요!
              </p>
              <div className="grid grid-cols-12 pt-5">
                <div className="col-span-6 sm:col-span-4">
                  <h5 className="text-[26px] text-orange-600 font-[600]">
                    12+
                  </h5>
                  <span>이상의 대학/기업에서 사용하고 있어요</span>
                </div>
                <div className="col-span-6 sm:col-span-4">
                  <h5 className="text-[26px] text-orange-600 font-[600]">
                    400+
                  </h5>
                  <span>
                    400명 이상의 사람들이 MaTS를 통해 모의경영을 경험했어요
                  </span>
                </div>
              </div>
              <div className="pt-6">
                <Link className="px-btn px-btn-theme mr-4" to="contactus">
                  서비스 이용/제휴 문의
                </Link>
                <Link className="px-btn px-btn-dark" to="portfolio">
                  서비스 예시
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
